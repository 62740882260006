import React from 'react';
import styled from 'styled-components';
import { Link, withPrefix } from 'gatsby';
import { headSM } from 'styles/typography';
import { rgba } from 'polished';

export const LinkGroup = styled.div`
  margin-bottom: 0;
  border-top: 1px solid ${props => props.theme.borderColor};
  padding: 24px 0;
  display: flex;
`;

export const PageLink = styled(Link)`
  flex: 1;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  flex-direction: ${props => (props.next ? 'row-reverse' : 'row')};
  img {
    margin: 0 8px;
    opacity: 0.4;
    transform: ${props => (props.next ? 'none' : 'rotate(180deg)')};
  }
  h4 {
    ${headSM};
    color: ${props => rgba(props.theme.textMain, 0.4)};
    letter-spacing: 0.22px;
    margin: 0 0 8px 0;
    line-height: 1.25;
    text-decoration: underline;
    max-width: 222px;
    text-align: ${props => (props.next ? 'right' : 'left')};
  }

  &:hover {
    h4 {
      color: ${props => rgba(props.theme.textMain, 0.7)};
    }
    img {
      height: 20px;
      width: auto;
      opacity: 0.7;
    }
  }
`;

export default function Pagination({ prev, next }) {
  return (
    <LinkGroup>
      {prev ? (
        <PageLink to={prev.slug}>
          <img src={withPrefix('/images/icons/chevron.svg')} alt="previous" />
          <div>
            <h4>{prev.text || prev.title}</h4>
          </div>
        </PageLink>
      ) : null}
      {next ? (
        <PageLink to={next.slug} next>
          <img src={withPrefix('/images/icons/chevron.svg')} alt="next" />
          <div>
            <h4>{next.text || next.title}</h4>
          </div>
        </PageLink>
      ) : null}
    </LinkGroup>
  );
}
