import React from 'react';
import Layout from 'layout';
import { graphql } from 'gatsby';
import DocsContent from 'components/Docs/DocsContent';
import Container from 'elements/Container';
import DocsLeftBar from 'components/Docs/Sidebar/Docs';
import DocsRightBar from 'components/Docs/Sidebar/TOC';
import { getNextPrevPage, getFilePath } from 'utils/links';
import { LOGO_PLACEHOLDER } from 'data/urls';

function mergeTocs(tocs) {
  const masterToc = tocs.reduce((acc, { node }) => {
    const { toc, path, collection } = node.frontmatter;
    return toc.reduce((secAcc, sec) => {
      const sections = secAcc;
      const { title, items, slug } = sec;
      sections[title] = sections[title] || { title, items: [] };
      if (sections[title].title === title) {
        const text = items[0] && items[0].text;
        const headingIndex = sections[title].items
          .map(item => item && item.text)
          .indexOf(text);
        if (headingIndex < 0) {
          const itemWithLinks = items.map(item => ({
            ...item,
            slug: getFilePath(collection, `${path}-${item.slug}`, item.slug),
          }));
          sections[title].items = sections[title].items.concat(itemWithLinks);
        }
        if (items.length === 0 && sections[title].items.length === 0) {
          const itemWithLinks = {
            text: title,
            slug: getFilePath(collection, `${path}-${slug}`, slug),
          };
          sections[title].items = sections[title].items.concat(itemWithLinks);
        }
      }
      return sections;
    }, acc);
  }, {});
  return Object.values(masterToc).map(value => value);
}

export default function DocumentPage({ data, location }) {
  const { doc, docList, meta } = data;
  const { slug, dirName } = doc.fields;
  const links =
    docList.edges.length > 0
      ? mergeTocs(docList.edges)
      : mergeTocs([{ node: doc }]);
  const metaData = meta?.document?.content || [];
  const productInfoInMeta = metaData.find(
    obj => obj.Folder_Name.toLowerCase() === dirName.toLowerCase()
  );
  const productInfo = {
    title: dirName,
    logo: LOGO_PLACEHOLDER,
    ...productInfoInMeta,
  };
  const { next, prev } = getNextPrevPage(links, slug);
  return (
    <Layout title={doc.frontmatter.title} location={location}>
      <Container style={{ display: 'flex' }}>
        <DocsLeftBar current={slug} links={links} productInfo={productInfo} />
        <DocsContent next={next} prev={prev}>
          {doc.html}
        </DocsContent>
        <DocsRightBar>{doc.tableOfContents}</DocsRightBar>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query($slug: String!, $dirName: String!) {
    doc: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      htmlAst
      tableOfContents(absolute: false, maxDepth: 2)
      fields {
        dirName
        slug
      }
      frontmatter {
        collection
        title
        toc {
          slug
          title
          items {
            text
            slug
          }
        }
        path
        slug
      }
    }

    docList: allMarkdownRemark(
      filter: {
        fields: { dirName: { eq: $dirName }, collection: { eq: "docs" } }
      }
    ) {
      edges {
        node {
          frontmatter {
            collection
            toc {
              slug
              title
              items {
                text
                slug
              }
            }
            path
          }
        }
      }
    }
    meta: googleSheet(
      document: {
        folder: { eq: "" }
        title: { eq: "GoTo Developer Platform" }
        name: { eq: "Docs" }
      }
    ) {
      id
      document {
        content {
          Logo
          Folder_Name
        }
        title
      }
    }
  }
`;
