import React, { useEffect } from 'react';
// import HelpLinks from 'components/Docs/HelpLinks';
import hljs from 'highlight.js/lib/core';
import Pagination from 'elements/Pagination';

import 'highlight.js/styles/atelier-estuary-light.css';
// import 'highlight.js/styles/atom-one-dark.css';
// import 'prism-themes/themes/prism-vs.css';
import javascript from 'highlight.js/lib/languages/javascript';
import python from 'highlight.js/lib/languages/python';
import java from 'highlight.js/lib/languages/java';
import sql from 'highlight.js/lib/languages/sql';
import bash from 'highlight.js/lib/languages/bash';
import yaml from 'highlight.js/lib/languages/yaml';
import json from 'highlight.js/lib/languages/json';
import go from 'highlight.js/lib/languages/go';

import { DocsContentBase, Content } from './style';

hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('python', python);
hljs.registerLanguage('java', java);
hljs.registerLanguage('sql', sql);
hljs.registerLanguage('bash', bash);
hljs.registerLanguage('yaml', yaml);
hljs.registerLanguage('json', json);
hljs.registerLanguage('go', go);

export default function DocsContent({ children, next, prev }) {
  useEffect(() => {
    document.querySelectorAll('code').forEach(code => {
      const parent = code.parentElement;
      if (parent.nodeName === 'PRE') {
        parent.removeAttribute('class');
        code.removeAttribute('class');
        hljs.highlightBlock(code);
      } else {
        code.removeAttribute('class');
        hljs.highlightBlock(code);
        code.classList.add('code-inline');
      }
      // if (code.classList[1] && code.classList[1] !== 'code-inline') {
      //   code.classList.add(`language-${code.classList[1]}`);
      // }
    });
  }, []);

  return (
    <DocsContentBase>
      <Content dangerouslySetInnerHTML={{ __html: children }} />
      {prev || next ? <Pagination prev={prev} next={next} /> : null}
      {/* <HelpLinks /> */}
    </DocsContentBase>
  );
}
