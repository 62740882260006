import styled, { css } from 'styled-components';
import { removeLink, circleBullet } from 'styles/mixins';
import { para } from 'styles/headings';
import rgba from 'polished/lib/color/rgba';
import { headLG, headMD, headSM, textSM, linkPrimary } from 'styles/typography';
import { flexWrap } from 'styles/utilities';

export const Content = styled.article(
  ({ theme }) => css`
    margin: 56px 0 0 0;
    padding-bottom: 80px;

    ol, ul {
      margin: 0;
      padding: 0;
      padding-left: 24px;
    }

    h1 {
      ${headLG};
      line-height: 32px;
      letter-spacing: 0.38px; 
      padding: 0;   
      margin: 56px 0 0 0;
    }

    h2 {
      ${headMD};
      letter-spacing: 0.31px;
      margin: 56px 0 0 0;
    }

    h3 {
      ${headMD};
      font-size: 18px;
      line-height: 24px;
      margin: 48px 0 0 0;    
    }

    h4 {
      ${headMD};
      font-size: 16px;
      line-height: 20px;
      margin: 40px 0 0 0;
    }

    h5 {
      ${headSM};
      line-height: 18px;
      margin: 32px 0 0 0;
    }

    h6 {
      ${headSM};  
      font-size: 12px;
      line-height: 16px;
      margin: 24px 0 0 0;
    }

    h1, h2, h3, h4, h5, h6  {
      ${removeLink};
      & + h2, & + h3, & + h4,& + h5{
        margin-top: 24px;
      } 
    }

    /* ul > li {
      ${flexWrap};
      align-items: end;
    } */

    li, p, li > p {
    
      ${para};
      line-height: 28px;
      color: ${rgba(theme.textMain, 0.5)};   

      strong {
        font-weight: bold;
      }
    }

    p {
      margin-top: 12px;
      & + & {
        margin-top: 16px;
      }

    }

    li {
      margin-top: 8px;
      p + p {
        margin-top: 16px;
      }
    }

    li, li > p {
      ol, ul {
        margin: 8px 0 16px 0px;
        padding-left: 0;
      }
    }
    a {
      ${headSM};
      font-size: 16px;
      ${linkPrimary};
      letter-spacing: 0.22px;
    }

    table {
      margin-top: 0;
      border: none;
      padding: 32px 0;
      border-spacing: 0;
      table-layout: auto;
      width: 100%; 
      a {
        font-size: 14px;
      }
    }

    thead>tr>th {
      background: #fafafa;
      color: rgba(0,0,0,.85);
      text-align: left;
      ${headSM};
      padding: 16px; 
    }
 
    tbody {
      background-color: ${theme.bgColor};
    }

    tr {
      padding: 16px 0;
    }

   

    td {
      ${textSM};
      font-size: 12px;
      letter-spacing: 0px;
      line-height: 16px;
      vertical-align: inherit;
      text-align: left; 
      padding: 16px; 
      border-bottom: 1px solid #f0f0f0;
    }


    p + p {
      margin-top: 16px;
    }

   ${circleBullet(22)};

   code {
    border: 1px solid ${props => props.theme.borderColor};
    border-radius: 2px;
    padding: 16px;
   }

   img, .gatsby-resp-image-wrapper {
     width: 100%;

     margin: 24px 0;
   }

   img {
    border: ${props => props.theme.borderColor};
   }

   .code-inline {
     display: initial;
     padding: 0px 4px;
     margin: 0 8px;
   }
   
  `
);

export const DocsContentBase = styled.main`
  border-left: 1px solid ${props => props.theme.borderColor};
  padding: 0 72px;
  flex: 1;
  max-width: 70%;
`;
