import { useState, useEffect } from 'react';
import throttle from 'lodash.throttle';

export default function useActiveHeading(
  tags = [1, 2, 3, 4, 5, 6],
  querySelector = 'article'
) {
  const [headings, setHeadings] = useState({
    titles: [],
    nodes: [],
    minDepth: 0,
  });
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeTitle, setActiveTitle] = useState({});
  function getHeadings() {
    const selector = tags.map(i => `${querySelector} h${i}`);
    const nodes = Array.from(document.querySelectorAll(selector));

    const titles = nodes.map(node => ({
      title: node.innerText,
      depth: Number(node.nodeName[1]),
      href: `#${node.getAttribute('id')}`,
    }));
    const minDepth = Math.min(...titles.map(h => h.depth));
    setHeadings({ titles, nodes, minDepth });
    setActiveTitle(titles[activeIndex]);
    return { titles, nodes };
  }

  useEffect(() => {
    const { titles, nodes } = getHeadings();
    const scrollHandler = throttle(() => {
      const elTops = nodes.map(el => el.getBoundingClientRect().top);
      const currentEl = elTops.reduce((acc, top, index) => {
        const number =
          window.pageYOffset === 0
            ? 0
            : top > 0 && top > window.innerHeight / 4
              ? acc
              : index;
        return number;
      }, 0);
      const currentIndex = currentEl === -1 ? titles.length - 1 : currentEl;
      setActiveIndex(currentIndex);
      setActiveTitle(titles[currentIndex]);
    }, 100);
    document.addEventListener('scroll', scrollHandler);
    return () => document.removeEventListener('scroll', scrollHandler);
  }, []);
  return { headings: headings.titles, activeIndex, activeTitle };
}
