import React, { useEffect } from 'react';
import useActiveHeading from 'hooks/useActiveHeading';
import { RightBar, TocH2, TocH34 } from './style';

export default function DocsRightBar() {
  const { activeIndex, headings } = useActiveHeading([2, 3, 4]);

  function scrollToEl(id) {
    const el = document.getElementById(id);
    if (el) {
      const y = el.getBoundingClientRect().top + window.pageYOffset - 100;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }

  function handleClick(e, href) {
    const id = href.replace('#', '');
    e.preventDefault();
    window.history.replaceState({}, '', e.target.href);
    scrollToEl(id);
  }

  useEffect(() => {
    const { hash } = window.location;
    const id = hash.replace('#', '');
    // delete hash so the page won't scroll to it
    window.location.hash = '';
    scrollToEl(id);
    window.history.replaceState({}, '', window.location.href + id);
  }, []);

  return (
    <RightBar>
      <nav>
        {headings.map(({ title, depth, href }, index) => {
          return depth === 2 ? (
            <TocH2
              key={`${title}${depth}`}
              active={activeIndex === index}
              href={href}
              onClick={e => handleClick(e, href)}
            >
              {title}
            </TocH2>
          ) : (
            <TocH34
              key={`${title}${depth}`}
              active={activeIndex === index}
              href={href}
              depth={depth}
              onClick={e => handleClick(e, href)}
            >
              {title}
            </TocH34>
          );
        })}
      </nav>
    </RightBar>
  );
}
