import React from 'react';
import { Link, withPrefix } from 'gatsby';
import { DOCS_URL } from 'data/urls';
import { PageLink, StickySidebar } from './style';

export default function DocsLeftBar({ links = [], current = '' }) {
  // const logo = (productInfo && productInfo.Logo) || productInfo.logo;
  // const title = productInfo && productInfo.title;
  return (
    <StickySidebar>
      <Link className="home-link" to={DOCS_URL}>
        <img src={withPrefix('/images/icons/back.svg')} alt="back" />
        Home
      </Link>

      {/* {title ? (
        <div className="product-name">
          <div className="logo">
            <img src={logo} alt={title} />
          </div>
          {title}
        </div>
      ) : null} */}
      <div>
        {links &&
          links.map(section => {
            return (
              <div key={section.title}>
                <div className="section-name">{section.title}</div>
                <div className="section-links">
                  {section.items && section.items.length ? (
                    section.items.map(link =>
                      link ? (
                        <PageLink
                          to={link.slug}
                          key={link.slug}
                          active={link.slug === current}
                        >
                          {link.text}
                        </PageLink>
                      ) : null
                    )
                  ) : (
                    <PageLink
                      to={section.slug}
                      active={section.slug === current}
                    >
                      {section.title}
                    </PageLink>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </StickySidebar>
  );
}
