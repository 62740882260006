import styled, { css } from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import { headSM, textSM } from 'styles/typography';
import { alignFlexCenter, marginBlock0 } from 'styles/utilities';
import SideBar from 'elements/Sidebar';
import { Link } from 'gatsby';

export const Category = styled.div`
  margin-bottom: 32px;
  h3 {
    ${headSM};
    color: ${({ theme }) => rgba(theme.textMain, 0.4)};
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    padding-bottom: 12px;
    ${marginBlock0};
  }

  a {
    ${alignFlexCenter};
    justify-content: flex-start;
    margin-bottom: 8px;
    img {
      height: 20px;
      width: 20px;
      object-fit: contain;
      margin-right: 12px;
    }
    span {
      ${textSM};
      line-height: 19px;
      text-transform: capitalize;
    }

    &:hover {
      span {
        color: ${({ theme }) => rgba(theme.textMain, 0.9)};
      }
    }
  }
`;

export const Container = styled(SideBar)`
  min-width: 240px;
  .home-link {
    ${alignFlexCenter};
    ${textSM};
    color: ${props => props.theme.textMain};
    letter-spacing: 0.22px;
    text-transform: uppercase;
    opacity: 0.6;
    line-height: 1;

    img {
      margin-right: 8px;
      height: 14px;
      width: auto;
    }
  }

  .product-name {
    margin-top: 14px;
    ${alignFlexCenter};
    ${headSM};
    font-size: 16px;
    text-transform: capitalize;
    letter-spacing: 0px;
    line-height: 19px;
  }

  .logo {
    ${alignFlexCenter};
    margin-right: 8px;
    img {
      height: 24px;
      object-fit: contain;
    }
  }

  .section-name {
    text-transform: uppercase;
    margin: 24px 0 10px 0;
    line-height: 1.25;
    ${headSM};
    font-size: 13px;
    letter-spacing: 0.19px;
  }

  .section-links {
    display: flex;
    flex-direction: column;
  }
`;

export const StickySidebar = styled(Container)`
  position: sticky;
  top: 66px;
  overflow-x: auto;
  height: calc(100vh - 66px);
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const PageLink = styled(Link)`
  ${textSM};
  color: ${({ theme, active }) =>
    active ? theme.primary : rgba(theme.textMain, 0.5)};
  margin-bottom: 8px;
  line-height: 1.25;

  &:hover {
    color: ${props => rgba(props.theme.textMain, 0.9)};
  }
`;

export const RightBar = styled(SideBar)(
  props => css`
    ${textSM};
    line-height: 1;
    letter-spacing: 0.22px;
    color: ${props.theme.textMain};
    position: sticky;
    top: 56px;
    height: fit-content;

    ol,
    ul {
      list-style: none;
      padding-left: 0;
      display: flex;
      flex-direction: column;
    }

    & > ul > li {
      margin-bottom: 12px;

      a {
        color: inherit;
        line-height: 1.3;
      }
      li {
        margin-top: 8px;
        padding-left: 0;
        opacity: 0.6;
      }
    }

    p {
      margin: 0 0 4px 0;
    }
  `
);

export const TocH2 = styled.a(props => {
  return css`
    ${headSM};
    color: ${props.active
    ? rgba(props.theme.textMain, 0.7)
    : rgba(props.theme.textMain, 0.4)};
    font-size: 12px;
    display: block;
    line-height: 1.125;
    margin-bottom: 8px;

    &:not(:first-of-type) {
      margin-top: 16px;
    }

    &:hover {
      color: ${rgba(props.theme.textMain, 0.9)};
    }
  `;
});

export const TocH34 = styled.a(props => {
  return css`
    ${textSM};
    font-size: 12px;
    color: ${props.active
    ? rgba(props.theme.textMain, 0.7)
    : rgba(props.theme.textMain, 0.4)};
    display: block;
    line-height: 1.125;
    margin-bottom: 8px;
    margin-left: ${props.depth === 4 ? '8px' : 0};
    &:hover {
      color: ${rgba(props.theme.textMain, 0.7)};
    }
  `;
});
