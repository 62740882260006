import styled from 'styled-components';

const Sidebar = styled.aside`
  width: max-content;
  min-width: 200px;
  padding: 56px 30px 16px 0px;
  max-width: 256px;
`;

export default Sidebar;
